<template>
  <PageConstructor
    :query="query"
    :columns="columns"
    id-name="uuid"
    :page-name="text.page_name[language]"
  >
  </PageConstructor>
</template>

<script>
import PageConstructor from "@/components/pageConstructor/PageConstructor.vue";
import text from "@/assets/text/fixtures.json";

export default {
  name: "Fixtures",
  data() {
    return {
      text,
      query: {},
      columns: [
        {
          name: "number",
          label: text.number,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "fixture_type",
          label: text.fixture_type,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "manufacturer",
          label: text.manufacturer,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "manufacturer_code",
          label: text.manufacturer_code,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "specialization",
          label: text.specialization,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "workshop",
          label: text.workshop,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "purchase_price",
          label: text.purchase_price,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "basic_resource",
          label: text.basic_resource,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
        {
          name: "machine_tool_number",
          label: text.machine_tool_number,
          options: {
            sort: true,
          },
          basis: 150,
          width: 150,
        },
      ],
    };
  },
  computed: {
    language() {
      return this.$store.state.index.language;
    },
  },
  components: {
    PageConstructor,
  },
};
</script>
